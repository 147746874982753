<template>
  <v-app style="background-color:#f8f9fb" >

        <!-- Diálogo de progresso -->
  <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a 90 dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilterDownload" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Em Manutenção
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilterDownload = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <v-container style="background-color:#f8f9fb;" >
    <v-col cols="12">
      <filtro-component :opcoes="opcoes" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'mes'"
      @filtrar="filterData" :title_opcao="'Situação'" :campos="'S'"
        ></filtro-component>
    </v-col>
  </v-container>
    <v-row>

      <v-col
      cols="12"
      sm="3"
      lg="3">

    </v-col>
    
      <v-col
      cols="12"
      sm="3"
      lg="3">
      
      <base-material-stats-card 
     color="#E9967A"
     icon="mdi-information"
     title="Serviços Finalizados"
     sub-icon-color="red"
     :valuex='false'
     :value='this.total_atendimento'
     :sub-text="'Total de Atendimento - Finalizado'"
   />
      
     </v-col>
  
  
     <v-col
     cols="12"
     sm="3"
     lg="3">

     <base-material-stats-card 
     color="#E9967A"
     icon="mdi-information"
     title="Serviços em Aberto"
     sub-icon-color="red"
     :valuex='false'
     :value='this.total_atendimento_aberto'
     :sub-text="'Total de Atendimento - Aberto'"
   />
   
    
  </v-col>
  
  
    <v-col
     cols="12"
     sm="3"
     lg="3">
     
      <base-material-stats-card 
      color="#E9967A"
      icon="mdi-information"
        title="Tota de Horas"
        sub-icon-color="red"
        :valuex='false'
        :value='format_hora(this.total_atendimento_min)'

        :sub-text="'Atendimentos Finalizados'"
        />
  
  
    </v-col>
  
  
  
    </v-row>

    <br/>
   
    <v-divider></v-divider>
    
    <br/><br/>


  <v-container style="background-color:#eee"  
    id="financeiro"
    fluid
    tag="section">
   
    
    <v-row>
   
  
    <v-col
    cols="6">

        <base-material-card
        icon="mdi-toolbox-outline"
        title="Ranking de Serviço - Quantidade"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="ranking_header"
              :items="lista_ranking"
              :items-per-page="10" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      
              <br/> <br/> <br/>
  
              <template v-slot:top>
                <v-progress-linear
                  v-if="isLoading" 
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </template>

                        <template v-slot:item="row">
                          
                         

                          <tr :class="{'cor-row': row.index % 2 !== 0, 'primeira-linha-estilo': row.index === 0}">
                               
                            <td class="linha" style="width: 5%;font-size:14px;"> 
                              <v-icon
                              color="black"
                              size="24"
                              class="ml-2 mr-1">
                              <template>
                                mdi-account-group-outline
                              </template>
                            </v-icon>
                            </td>

                            <td class="linha" style="width: 70%;font-size:14px;"> 
                              {{row.item.nome}}
                            </td>

                            <td class="linha" style="width: 20%;font-size:14px;"> 
                              {{row.item.qtd}}
                            </td>
                                
                          </tr>
                      </template>
              </v-data-table>


      <br/>
  
        <div class="d-flex justify-end" >
          <div style="width: 60px;">
            <span @click="download_xls"  style="color:green; font-size: 32px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-excel"></i> 
            </span>
            
            <br/>
          
          </div>
        </div>  


    <br/>

    </base-material-card>
  
    </v-col>

    <v-col
    cols="6">

        <base-material-card
        icon="mdi-toolbox-outline"
        title="Ranking de Serviço - Horas"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="ranking_header_min"
              :items="lista_ranking_min"
              :items-per-page="10" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      
              <br/> <br/> <br/>
  
              <template v-slot:top>
                <v-progress-linear
                  v-if="isLoading" 
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </template>

                        <template v-slot:item="row">
                          
                         

                          <tr :class="{'cor-row': row.index % 2 !== 0, 'primeira-linha-estilo': row.index === 0}">
                               
                            <td class="linha" style="width: 5%;font-size:14px;"> 
                              <v-icon
                              color="black"
                              size="24"
                              class="ml-2 mr-1">
                              <template>
                                mdi-account-group-outline
                              </template>
                            </v-icon>
                            </td>

                            <td class="linha" style="width: 65%;font-size:14px;"> 
                              {{row.item.nome}}
                            </td>

                            <td class="linha" style="width: 25%;font-size:14px;"> 
                              {{ Math.floor(row.item.valor / 60) + 'h ' + (row.item.valor % 60) + 'min' }}
                            </td>
                                
                          </tr>
                      </template>
              </v-data-table>


      <br/>
  
        <div class="d-flex justify-end" >
          <div style="width: 60px;">
            <span @click="download_xls"  style="color:green; font-size: 32px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-excel"></i> 
            </span>
            
            <br/>
          
          </div>
        </div>  


    <br/>

    </base-material-card>
  
    </v-col>



    <v-col
    cols="12">

        <base-material-card
        icon="mdi-toolbox-outline"
        title="Atendimentos - Todos os Eventos"
        color="green" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="rankingHeaderMin"
              :items="lista_funeraria"
              :items-per-page="40" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      
              <br/> <br/> <br/>
  
              <template v-slot:top>
                <v-progress-linear
                  v-if="isLoading" 
                  indeterminate
                  color="green"
                ></v-progress-linear>
              </template>

                        <template v-slot:item="row">
                          
                         

                          <tr :class="{'cor-row': row.index % 2 !== 0, 'primeira-linha-estilo': row.index === 0}">
                           
                            <td class="linha" style="width: 4%;font-size:14px;"> 
                              <v-icon
                              color="black"
                              size="24"
                              class="ml-2 mr-1">
                              <template>
                                mdi-toolbox-outline
                              </template>
                            </v-icon>
                            </td>


                            <td class="linha" style="width: 15%;font-size:14px;"> 
                              {{row.item.abertura}}
                            </td>

                            <td class="linha" style="width:35%;font-size:14px;"> 
                              {{ row.item.cliente }} <br/>  &#10132; EVENTO: {{ row.item.evento }} <br/>
                            </td>


                            <td class="linha" style="width: 15%;font-size:14px;"> 
                              {{row.item.inicio}} 
                            </td>

                            <td class="linha" style="width: 15%;font-size:14px;"> 
                              {{ row.item.termino !== '01/01/1900 00:00' ? row.item.termino : '' }}
                            </td>

                            <td class="linha" style="width: 10%;font-size:14px;"> 
                              {{ Math.floor(row.item.tempo / 60) + 'h ' + (row.item.tempo % 60) + 'min' }}
                            </td>
                                
                          </tr>
                      </template>
              </v-data-table>


      <br/>
  
        <div class="d-flex justify-end" >
          <div style="width: 60px;">
            <span @click="download_xls"  style="color:green; font-size: 32px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-excel"></i> 
            </span>
            
            <br/>
          
          </div>
        </div>  


    <br/>

    </base-material-card>
  
    </v-col>

  

  </v-row>


  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto
  import Funeraria from '../../services/funeraria' 
  import { ContentLoader } from 'vue-content-loader'

  export default {
    name: 'Obitos',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,

    },

    created() {
      this.selectedStartDate = this.getFirstDayOfMonth(),
      this.selectedEndDate =  this.formatDateBR(new Date())

      this.selectedStartDate2 = this.getFirstDayOfMonth(),
      this.selectedEndDate2 =  this.formatDateBR(new Date())
    },


  

    
    mounted() {
     this.carregarLoad(),
     this.dashboard();
     this.handleFilterChange(this.selectMode, this.selectedStartDate, this.selectedEndDate, this.selectedStartDate2, this.selectedEndDate2);
    },
   

    data () {
      return {
        ranking_header: [
          { text: '', value: '' },
          { text: 'Nome', value: 'nome' },
          { text: 'Qtd', value: 'qtd' },

        ],
        ranking_header_min: [
          { text: '', value: '' },
          { text: 'Nome', value: 'nome' },
          { text: 'Horas', value: 'valor' },

        ],
         rankingHeaderMin: [
          { text: '', value: '' },
          { text: 'Abertura do Atendimento', value: 'abertura' },
          { text: 'Colaborador/Evento Atual', value: 'cliente' },
          { text: 'Início do Evento', value: 'inicio' },
          { text: 'Término do Evento', value: 'termino' },
          { text: 'Duração Total', value: 'tempo' }
        ],

         lista_ranking: [],
         lista_ranking_min: [],
         lista_funeraria: [],
         produto: 'Todos',
         bool_03 : true,
         bool_02: true,
         total_atendimento_aberto: 0,
         total_atendimento_min: 0,
         total_atendimento: 0,
         subTextAddDynamic: "Total Recebido:",
         recebido_acordo:0,
         qtd_recebido_acordo:0,
         criado_acordo: 0,
         animationInterval: null, // Intervalo para controlar a animação
         empresa: '',
         selectedCompany: "Todas",
         selectedType: "Todos",
         selectedOpcao: "Todas",
         selectMode: "Vencimento",
         selectedStartDate : "",
         selectedEndDate : "",
         isLoading: false,
         modos: ["Período"],
        modos2: ["SIM", "NÃO"],
          campos: 'N',
          categoria: [],
          showFilter: false,
          showProgress: false,
          showFilterDownload: false,
          opcoes: ["Todas","COM SEGURO", "SEM SEGURO"],

          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PARQUE DAS FLORES", "PREVIDA", ],
          tipos: ["Todos", "Planos"],
          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],

       

           lista_nome_empresa:[],
      
      }
    },

   
    methods: {
     
      
      

        isDateValid(dateString) {
          // Verifica se a string é uma data válida
          const date = new Date(dateString);
          return !isNaN(date.getTime());
        },
        areDatesValid(dates) {
          // Verifica se todas as datas na matriz são válidas
          return dates.every(dateString => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
          });
        },

        handleFilterChange(selectedFilter, dataI, dataF, DataRI, DataRF) {

         

          if (selectedFilter === 'Vencimento') {
            this.subTextAddDynamic = 'Total Recebido: ';
          } else if (selectedFilter != 'Vencimento') {

            if (
              converteDataParaFormatoISO(DataRI) <= converteDataParaFormatoISO(dataI) &&
              converteDataParaFormatoISO(DataRF) > converteDataParaFormatoISO(dataF)
              ) {
                this.subTextAddDynamic = 'Total Recebido com Acréscimo: ';
              }else{
                this.subTextAddDynamic = 'Total Recebido: ';

              }
          }

        },

      
        


      filterData(valores) {
        
          this.carregarLoad(),
          this.isLoading = true;
          let nova_consulta = false;

          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;

          this.selectMode = "Vencimento";


          this.handleFilterChange(valores.selectMode, valores.selectedStartDate, valores.selectedEndDate, valores.selectedStartDate2, valores.selectedEndDate2);
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.selectedStartDate2 = valores.selectedStartDate2;
          this.selectedEndDate2 = valores.selectedEndDate2;
          this.selectedStartDate3 = valores.selectedStartDate3;
          this.selectedEndDate3 = valores.selectedEndDate3;
          this.produto = valores.selectedProduto;
          //this.gf01();
          this.dashboard();
         
      },

      
      filtrarTiposFunerarios(tipoFunerario) {
        const tiposPermitidos = "Todos";
        if (this.tipoFunerario === "Jazigo") {
           tiposPermitidos = ["CONCESSÃO DE COLUMBÁRIO", "CONCESSÃO DE JAZIGOS", "CONCESSÃO DE OSSÁRIO", "VENDA DE COLUMBÁRIO", "VENDA DE JAZIGOS - IMEDIATO", "VENDA DE JAZIGOS - PREVENTIVO", "VENDA DE JAZIGOS - TRANSITÓRIO"];
        }
        if (this.tipoFunerario === "Plano") {
           tiposPermitidos = ["COMPLETO",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS"];
        }

        if (this.tipoFunerario === "Cremação") {
           tiposPermitidos = [ "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "URNAS CINERARIAS"];
        }

        if (this.tipoFunerario === "Todos" ||  this.tipoFunerario ===  "Funerária" || this.tipoFunerario ===  "Serviços Administrativos" || this.tipoFunerario ===  "Serviços Cemiteriais") {
           tiposPermitidos = [ "Todos"];
        }

        if (tiposPermitidos.includes(tipoFunerario)) {
          return true; // Mantém apenas os tipos permitidos
        } else {
          return false; // Remove os tipos não permitidos
        }
      },
     
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },

        startAnimation(targetValue) {
            this.animationInterval = setInterval(() => {
              if (this.animatedValue < targetValue) {
                this.animatedValue += 1; // Atualiza o valor animado
              } else {
                clearInterval(this.animationInterval); // Interrompe a animação quando atingir o valor desejado
              }
            }, 10); // Intervalo em milissegundos entre cada atualização
          },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      carregarLoad(){

         this.bool_03 = true
         this.bool_03 = true;

      },

      async dashboard() {

              this.isLoading = true;

              this.selectMode = "Vencimento";
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              const formattedStartDate2 = converteDataParaFormatoISO(this.selectedStartDate2);
              const formattedEndDate2 = converteDataParaFormatoISO(this.selectedEndDate2);

              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 90) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        this.dias = 90;
                        return 
                      }
                   
                      Funeraria.qtd_atendimento(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.total_atendimento = response.data;  
                            
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.isLoading = false;

                          })  

                          Funeraria.qtd_atendimento_aberto(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.total_atendimento_aberto = response.data;  
                            
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.isLoading = false;

                      })  

                      Funeraria.qtd_atendimento_min(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                        if(response.status === 200){
                          this.total_atendimento_min = response.data;  
                          
                        } 

                        }).catch(e => {    
                        }).finally(() => {
                          this.isLoading = false;

                        })  

                        Funeraria.lista_ranking_qtd(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                          if(response.status === 200){
                            this.lista_ranking = response.data;  
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.isLoading = false;

                          }) 
                          
                          Funeraria.lista_ranking_min(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                              if(response.status === 200){
                                this.lista_ranking_min = response.data;  
                              } 

                              }).catch(e => {    
                              }).finally(() => {
                                this.isLoading = false;

                              })
                    
                          Funeraria.lista_funeraria(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, "Todos",  this.selectedOpcao, this.produto).then(response => {

                              if(response.status === 200){
                                this.lista_funeraria = response.data;  
                              } 

                              }).catch(e => {    
                              }).finally(() => {
                                this.isLoading = false;

                              }) 



                        

                          
                          
      },
   

      download_xls() {

          //this.showProgress = true;              
          const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
          const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
          const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);


          if (diferencaEmDias > 90) {
            this.isLoading = false;
            this.showFilter = true;
            this.dias = 90;
            return 
          }

          this.showFilterDownload = true;
        },

          

      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },


    getStatus (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'green lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },

      calcularComissao: function(empresa, resultado, comissao, original, pago) {
        
        if (empresa === "PREVIDA") {
          if (resultado < 0) {
            // Se o resultado for negativo, retorna a diferença entre pago e original
            return pago - original;
          } else {
            // Se o resultado não for negativo, retorna a comissão
            return comissao;
          }
        } else {
          // Se a empresa for diferente de "Previda", retorna a comissão
          return comissao;
        }
      },

      format_hora(valor){
        return Math.floor(valor / 60) + 'h ' + (valor % 60) + 'min';
      },

      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },

    },
  }


   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

 
  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }



</script>


<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.custom-gray-background {
  height: 90px;
}

.row {
  display: flex;
  flex-wrap: wrap;
   flex: 0 1 auto !important; 
  margin: -12px;
}


/* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
.fill-width {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.v-menu__content{
  box-shadow:none !important;
  top: 4px !important;
}
/* Centralize horizontalmente */
.justify-center {
  justify-content: center;
}

.gray-row {
  background-color: #e7f1ff; /* ou qualquer outra cor cinza que você preferir */
  
}
.cor-row {
  background-color: #f8ded4   ;
  transition: none !important; /* Desativa a transição de cor */

}


.linha{

    font-size: 11px !important;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .no-hover-effect.v-data-table tbody tr:hover {
    color: #3055a4;
  }
.card-container {
  display: flex;
  justify-content: space-around; /* ou outro alinhamento que desejar */
}

.primeira-linha-estilo {
    font-size: 5px !important;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

</style>
